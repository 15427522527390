import { mapGetters, mapMutations, mapState } from 'vuex'
import { recaptcha } from '../../../mixins/recaptcha'
import CompanyLoading from '../CompanyLoading'

export default {
  name: 'CompanyContainer',
  mixins: [recaptcha],

  components: {
    CompanyLoading,
    LeftGroupActions: () => import('./LeftGroupActions'),
    RightGroupActions: () => import('./RightGroupActions'),
  },

  async mounted() {
    const { rc: code /* rc = report code */ } = this.$route.query

    if (code) {
      return
    }

    const { agree, slug, slugId } = this.$route.params

    if (!agree) {
      this.$router.push({ name: 'company', params: { slug, slugId } })
    }
  },

  data() {
    const { source } = this.$route.params

    return {
      source,
      isLoading: false,
    }
  },

  computed: {
    ...mapState(['company', 'loading']),
    ...mapGetters(['isSourceCollaborator']),

    isCollaborator() {
      return this.isSourceCollaborator(this.source)
    },
  },

  methods: {
    ...mapMutations(['setError', 'setNotice', 'setLoading']),

    handleReportButtonClick(isAnonymousReport) {
      return !isAnonymousReport && this.isCollaborator
        ? this.redirectToRestrictReportForm()
        : this.redirectToOpenReportForm(isAnonymousReport)
    },

    redirectToRestrictReportForm() {
      this.$router.push({ name: 'new-report', params: { source: this.source } })
    },

    async redirectToOpenReportForm(isAnonymousReport) {
      this.isLoading = true
      this.setNotice('WAIT')

      const recaptchaSuccess = await this.performRecaptcha('company')

      if (recaptchaSuccess) {
        const { slug, slugId } = this.company

        this.isLoading = false

        this.$router.push({
          name: 'company-report',
          params: {
            anonymous: isAnonymousReport,
            source: this.source,
            slug,
            slugId,
            agree: true,
          },
        })
      } else {
        this.isLoading = false
        this.setLoading(false)
        this.setError('INTERNAL_SERVER_ERROR_TITLE')
      }
    },
  },

  watch: {
    loading(value) {
      this.isLoading = value
    },
  },
}

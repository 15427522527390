import iconLoading from '../../../assets/images/icon_loading.svg'

export default {
  name: 'CompanyLoading',
  props: {
    isOpened: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    iconLoading,
  }),
}
